import Header from "../organisms/Header"
import Back from "../atoms/back"
interface AccountProps {
    user: any;
    isLoggedIn: boolean;
  }
  
const Pay: React.FC<AccountProps> = ({ user, isLoggedIn } : AccountProps) => {
//function Pay() {
    return (
        <>
            <Header user={user}/>
            <div>
                <Back path="/icons/back.png" destination="/"/>
            </div>
            <div className="max-w-xl mx-auto mt-20 p-6 bg-primary_color rounded-md shadow-md">
                <form>
                    <div className="mb-4">
                        <label htmlFor="cardNumber" className="block text-black text-sm font-bold mb-2">
                            Card Number
                        </label>
                        <input
                            type="text"
                            id="cardNumber"
                            name="cardNumber"
                            className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                            placeholder="Card Number"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="streetAddress" className="block text-black text-sm font-bold mb-2">
                            Street Address
                        </label>
                        <input
                            type="text"
                            id="streetAddress"
                            name="streetAddress"
                            className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                            placeholder="Street Address"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="country" className="block text-black text-sm font-bold mb-2">
                            Country
                        </label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                            placeholder="Country"
                        />
                    </div>
                    <div className="mb-4 grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="city" className="block text-black text-sm font-bold mb-2">
                                City
                            </label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                                placeholder="City"
                            />
                        </div>
                        <div>
                            <label htmlFor="state" className="block text-black text-sm font-bold mb-2">
                                State
                            </label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                                placeholder="State"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="zipcode" className="block text-black text-sm font-bold mb-2">
                            Zipcode
                        </label>
                        <input
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                            placeholder="Zipcode"
                        />
                    </div>
                    <div className="mb-4 grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="name" className="block text-black text-sm font-bold mb-2">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                                placeholder="Name"
                            />
                        </div>
                        <div>
                            <label htmlFor="prename" className="block text-black text-sm font-bold mb-2">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="prename"
                                name="prename"
                                className="w-full p-2 border rounded-md bg-bg_color text-text_color"
                                placeholder="Prename"
                            />
                        </div>
                    </div>
                    <button className="btn text-white font-bold py-2 px-4 rounded-md">
                        Submit
                    </button>
                </form>
            </div>
        </>
    );
}

export default Pay;
