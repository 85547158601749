import DisplayGrid from "./DisplayGrid";

function PlaylistSong(props: { onClick: (index: number) => void, id: number, title: string, artist:string, img:string, length:string} ) {
    return (
        <>
        <div className="flex flex-row justify-start shadow-md rounded-xm" onClick={() => props.onClick(props.id)}>
            <img className="h-20 w-20" src={props.img} alt=""/>
            <div className="playlist-song flex flex-row justify-between w-full">
                <h1 className="flex items-center justify-center  w-36 h-auto overflow-clip">{props.title}</h1>
                <h1 className="flex items-center justify-center  w-36 h-auto overflow-clip">{props.artist}</h1>
                <h1 className="flex items-center justify-center  w-36 h-auto overflow-clip">{props.length}</h1>
            </div>
        </div>
        </>
    );
}
export default PlaylistSong;