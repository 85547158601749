function AudioPlayerRight(props: { Title: string, Artist: string, Cover: string}) {
    return (
        <div className="flex-2 flex p-4 flex-row audioplayer-right"> {/* Right */}
            <div className="flex">
                <img className="cover h-20 w-20 rounded-b" alt="Cover" src={props.Cover}/>
                <div>
                    <h1 className="cover-title text-3xl self-center p-2 font-bold text-text_color">{props.Title}</h1>
                    <p className="cover-text px-2 text-2xl text-text_color">{props.Artist}</p>
                </div>
            </div>
            <button className="cover-button px-10"><img className="h-14 w-14 svgs" src="./icons/iconMenu.svg" alt="Icon Menu"/></button>
        </div>
    );
}
export default AudioPlayerRight;