import AudioPlayer from "../organisms/AudioPlayer";
import HomeContent from "../organisms/HomeContent";
import {useState} from "react";
import Header from "../organisms/Header";
import Menu from "../organisms/Menu";
import {AudioPlayerProps, HomeProps} from "../interfaces";


interface AccountProps {
    user: any;
    isLoggedIn: boolean;
  }
  
const Home: React.FC<AccountProps> = ({ user, isLoggedIn } : AccountProps) => {
//function Home() {
    const [selectedSong, setSelectedSong] = useState<AudioPlayerProps>( { index: -1 })

    const onClick = (index: number) : void => {
        setSelectedSong({ index: index } );
    };

    console.log("Hi from Home")

    return (
        <>
            <Header user={user}  />
            <div className="flex flex-row margin-bottom-100">
                <Menu />
                <HomeContent onClick={onClick}/>
                {/* Render AudioPlayer with the default values */}
                <AudioPlayer index={selectedSong.index} />
            </div>
        </>
    );
}

export default Home;
