import Back from "../atoms/back";
import LoginTitle from "../organisms/LoginTitle";
import LoginForm from "../organisms/LoginForm";
import SignupLink from "../organisms/SignupLink";

function Login() {


    return (
        <>
            <div className="darkmode min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 pt-0 ">
                <div className="max-w-xl w-full space-y-8">
                    <LoginTitle/>
                    <LoginForm/>
                    <div className="text-center text-sm mt-4">
                        <SignupLink/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
