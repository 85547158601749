import PlaylistIcon from "../atoms/PlaylistIcon";
function PlaylistIcons(props: {className: string}) {
    return (
      <div className={props.className}>
      <PlaylistIcon url="./icons/play.svg" name="Play" />
      <PlaylistIcon url="./icons/filter.svg" name="Filter" />
      <PlaylistIcon url="./icons/edit.svg" name="Edit" />
      <PlaylistIcon url="./icons/trash-2.svg" name="Edit" />
      
      </div>
    );
  }
  
  export default PlaylistIcons;