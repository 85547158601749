function PlaylistIcon(props: {url: string, name: string}) {
    return (
      <>
      <div className="rounded-full hover:bg-gray-200 w-24 h-24 mb-10 m-5 bg-secondary_color">
      <img
        src={props.url}
        alt={props.name}
        className="h-20 w-20 p-2 ml-2 mt-2"
      />
      </div>
      </>
    );
  }
  
  export default PlaylistIcon;