import React, { useRef } from "react";
import { AudioPlayer } from "../interfaces";

function AudioPlayerBar({ audioElem, currentSong }: AudioPlayer) {
    const clickRef = useRef<HTMLDivElement>(null);

    const checkWidth = (e: React.MouseEvent<HTMLDivElement>) => {
        const width = clickRef.current?.clientWidth || 0;
        const offset = e.nativeEvent.offsetX;

        const divprogress = (offset / width) * 100;
        if (audioElem.current) {
            audioElem.current.currentTime = (divprogress / 100) * (currentSong.length || 0);
        }
    };

    return (
        <div className="flex-grow py-4 w-full fixed bottom-[5rem] audioplayerbar">
            <div className="relative h-4 bg-primary" onClick={checkWidth} ref={clickRef}>
                <div
                    className="absolute z-30 top-0 left-0 h-full bg-secondary_color"
                    style={{ width: `${currentSong.progress || 0}%` }}
                ></div>
            </div>
        </div>
    );
}

export default AudioPlayerBar;
