import React from "react";
import { Link } from "react-router-dom"; // Import Link from your router library

interface SubscriptionPlanProps {
  title: string;
  description: string;
  price: number;
  linkTo: string;
  discount?: number;
  icon: string;
}

function SubscriptionPlan({
  title,
  description,
  price,
  discount,
  icon,
  linkTo, 
}: SubscriptionPlanProps) {
  const planStyle = {
    padding: "2rem",
    fontSize: "1.25rem",
  };

  return (
    <div className="card w-72 bg-base-100 shadow-xl">
      <figure><img src={icon} alt="Shoes" /></figure>
      <div className="card-body">
        <h2 className="card-title">{title}
        { discount && (
        <div className="badge badge-secondary">-{discount}%</div>
    )}
        </h2>
        <p>{description}</p>
        <div className="card-actions justify-end">
          <p>{price}/Month</p>
          <a href={linkTo}>
          <button className="btn btn-primary">Subscribe</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPlan;
