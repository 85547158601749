import SidebarIcon from "../atoms/SidebarIcon";

function SidebarIcons() {
    return (
        <>

            <div className="nav-button" title="Home">
            <SidebarIcon icon={"./icons/home.png"} link={"/"} altText={"1"}/>
            </div>
            <div className="nav-button" title="Libary">
            <SidebarIcon icon={"./icons/library.png"} link={"/library"} altText={"2"}/>
            </div>
            <div className="nav-button" title="Upgrade">
            <SidebarIcon icon={"./icons/upgrade.png"} link={"/subscription"} altText={"3"}/>
            </div>
        </>
    );
}

export default SidebarIcons;
