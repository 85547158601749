import React, { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import Loading from '../atoms/Loading';

function Logout() {
  console.log('Logging out...');
  let auth = getAuth(); // Declare auth globally

  useEffect(() => {
    async function logoutUser() {
      try {
        await signOut(auth); // Use the globally declared auth
        alert('User logged out successfully');
        window.location.href = '/login';
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }

    logoutUser();
  }, []);

    return <Loading />;
}

export default Logout;
