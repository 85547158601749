import SidebarIcon from "../atoms/SidebarIcon";
import Back from "../atoms/back";

function AccountMenu() {
    return (
        <div className="h-screen w-16 fixed">
            <div title="Back">
                <Back path="/icons/back.png" destination="/"/>
            </div>
            <div title="Account">
                <SidebarIcon icon={"./icons/profile_icon.png"} link={"/account"} altText={"2"}/>
            </div>
            <div title="Subscription">
                <Back path="/icons/money.png" destination="/subscription"/>
            </div>
        </div>
    );
}

export default AccountMenu;