import DisplayGrid from "../molecules/DisplayGrid";
import {AudioPlayerProps, HomeProps, PlayableSong, PlayingSong, Song} from "../interfaces";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import {useEffect, useState} from "react";
import 'firebase/auth';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
    authDomain: "soundhub-m165.firebaseapp.com",
    projectId: "soundhub-m165",
    storageBucket: "soundhub-m165.appspot.com",
    messagingSenderId: "235438311528",
    appId: "1:235438311528:web:7e01620b75439c2bda93dd",
    measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();


function HomeContent({ onClick }: HomeProps) {
    const [songs, setSongs] = useState<PlayableSong[]>([]);

    useEffect(() => {
        async function fetchSongs() {
            const songCollection = collection(firestore, "songs"); // Change "playlists" to your actual collection name
            const songsSnapshot = await getDocs(songCollection);
            const songsDataRaw = songsSnapshot.docs.map(doc => doc.data() as PlayableSong);
            let songsData = songsDataRaw.sort((a, b) => a.id - b.id);
            songsData.forEach((song, index) => {
                song.onClick = () => onClick(index);
            });
            setSongs(songsData);
        }

        fetchSongs();
    }, []);
    return (
        <div className="pt-100 mt-0 flex flex-col w-full playlist-list">
            <h1 className="text-2xl text-text_color font-bold">Last Played</h1>
            <DisplayGrid songs={songs} playlists={undefined}/>
            <h1 className="text-2xl text-text_color text-white-100 font-bold">Recommended</h1>
            <DisplayGrid songs={songs} playlists={undefined}/>
        </div>
    );
}

export default HomeContent;