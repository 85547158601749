import React, { useEffect, useState } from "react";

interface AudioPlayerLeftProps {
    isPlaying: boolean;
    audioElem: React.RefObject<HTMLAudioElement>;
    currentSong: any; // Assuming you have a state for the current song
    setCurrentSong: React.Dispatch<React.SetStateAction<any>>; // Assuming you have a setter for the current song
    skipBack: () => void;
    PlayPause: () => void;
    skiptoNext: () => void;
    volumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    toggleVolumeSlider: () => void;
    volume: number;
    currentTime: string;
    duration: string;
    showVolumeSlider: boolean;
}

function AudioPlayerLeft({
    volume,
    currentTime,
    duration,
    showVolumeSlider,
    isPlaying,
    skipBack,
    PlayPause,
    skiptoNext,
    volumeChange,
    toggleVolumeSlider,
}: AudioPlayerLeftProps) {

    return (
        <div className="flex flex-1 p-4 gap-2 flex-row audioplayer-left">
            <div className="flex">
                <button onClick={skipBack}>
                    <img className="h-14 w-14 rotate-180 svgs" src="./icons/backforward.svg" alt="back button" />
                </button>
                <button onClick={PlayPause}>
                    {!isPlaying ? (
                        <img className="h-16 w-16 svgs" src="./icons/playerPause.svg" alt="Pause state" />
                    ) : (
                        <img className="h-16 w-16 svgs" src="./icons/playerPlay.svg" alt="Play" />
                    )}
                </button>
                <button onClick={skiptoNext}>
                    <img className="h-14 w-14 svgs" src="./icons/backforward.svg" alt="skip button" />
                </button>
            </div>
            <div className="flex">
                <h1 className="mx-2 self-center text-text_color text-1xl">
                    {currentTime} / {duration}
                </h1>
                <button onClick={toggleVolumeSlider}>
                    <img className="h-14 w-14 svgs" alt="Speaker" src="./icons/speaker.svg" />
                </button>
                {showVolumeSlider && (
                    <input
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={volume}
                        onChange={volumeChange}
                        className="ml-2"
                    />
                )}
            </div>
        </div>
    );
}

export default AudioPlayerLeft;
