import React, { useState } from "react";

function HeaderProfileIcon(props: {
  icon?: string;
  name?: string;
  isLoggedIn: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getUsernameInitals = (email: string) => {
    let expected_name = email.split("@")[0];
    expected_name = expected_name.slice(0, 2)
    return expected_name.toUpperCase();
  }

  return (
    <div className="relative" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
      <a href={"/account"}>
      {props.name? <div className="avatar online placeholder">
  <div className="bg-neutral text-neutral-content rounded-full w-12">
    <span className="text-white-100 text-2xl">{getUsernameInitals(props.name)}</span>
  </div>
</div>   : <div className="avatar offline">
          <div className="w-11 rounded-full">
             <img src={props.icon} />
          </div>
        </div> }
      </a>
      {isOpen && (
        <div className="absolute right-0 mt-0 bg-base-100  w-40 border border-base-100 rounded shadow-md">
          <ul>
            {props.isLoggedIn ? ( // Conditionally render based on authentication status XD
              <>
                <li>
                  <a href="/account" className="block px-4 py-2 text-text_color hover:bg-primary">
                    Account
                  </a>
                </li>
                <li>
                  <a href="/logout" className="block px-4 py-2 text-text_color hover:bg-primary">
                    Logout
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="/login" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                    Login
                  </a>
                </li>
                <li>
                  <a href="/signup" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                    Signup
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default HeaderProfileIcon;