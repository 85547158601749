import Header from "../organisms/Header";
import Menu from "../organisms/Menu";

const Loading = () => {
  return (
    <>
      <Header />
      <Menu />
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 loading-color"></div>
      </div>
    </>
  );
};

export default Loading;
