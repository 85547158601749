import {Link} from "react-router-dom";

interface BackProps {
    path: string;
    destination: string;
}
function Back(props: BackProps) {
    return (
        <div className="mx-2.5 my-2.5">
            <Link to={props.destination}>
                <img src={props.path} alt="Go Back" className="w-12 h-12"/>
            </Link>
        </div>
    );
}

export default Back;
