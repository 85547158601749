import SidebarIcons from "../molecules/SidebarIcons";
function Menu() {
    return (
        <div className="fixed">
            <SidebarIcons />
        </div>
    );
}

export default Menu;
  