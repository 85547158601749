import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Account from './components/pages/Account';
import Library from './components/pages/Library';
import Subscription from './components/pages/Subscription';
import Pay from './components/pages/Pay';
import Error404 from './components/pages/Error404';
import Playlistpage from "./components/pages/Playlistpage";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import Logout from "./components/pages/Logout";
import Home from "./components/pages/Home";
import Loading from './components/atoms/Loading';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
  authDomain: "soundhub-m165.firebaseapp.com",
  projectId: "soundhub-m165",
  storageBucket: "soundhub-m165.appspot.com",
  messagingSenderId: "235438311528",
  appId: "1:235438311528:web:7e01620b75439c2bda93dd",
  measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed:", user);
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />
  }

  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" element={currentUser ? <Home user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" /> } />
        <Route path="/login" element={currentUser ? <Navigate to="/" /> : <Login />} />
        <Route path="/signup" element={currentUser ? <Navigate to="/" /> : <Signup />} />
        <Route path="/logout" element={currentUser ? <Logout />: <Login />} />
        <Route path="/account" element={currentUser ? <Account user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
        <Route path="/library" element={currentUser ? <Library user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
        <Route path="/playlist" element={currentUser ? <Playlistpage user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
        <Route path="/subscription" element={currentUser ? <Subscription user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
        <Route path="/pay" element={currentUser ? <Pay user={currentUser} isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;