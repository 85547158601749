function SidebarIcon(props: { link: string; icon: string; altText: string; }) {
    const iconStyle = {
        backgroundColor: 'black',
        borderRadius: '50%',
        padding: '10px',
    };

    return (
        <div className="mx-2 my-2.5">
        <a href={props.link}>
            <div style={iconStyle}>
                <img
                    src={props.icon || "./icons/soundhub_logo.png"}
                    alt={props.altText}
                    className="h-auto w-auto"
                />
            </div>
        </a>
        </div>
    );
}

export default SidebarIcon;
