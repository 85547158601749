function HeaderLogo() {
  return (
    <>
      <img
        src="./icons/soundhub_logo.png"
        alt="Logo"
        className="logo"
      />
      <a href="/" className="text-3xl font-bold navigation-logo-text text-black ">SoundHub</a>
    </>
  );
}

export default HeaderLogo;
