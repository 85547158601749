import React from "react";
import Header from "../organisms/Header";
import SubscriptionPlan from "../organisms/SubscriptionPlan";
import AccountMenu from "../organisms/AccountMenu";
interface AccountProps {
    user: any;
    isLoggedIn: boolean;
  }
  
const Subscription: React.FC<AccountProps> = ({ user, isLoggedIn } : AccountProps) => {
//function Subscription() {
    return (
        <>
            <Header user={user}/>
            <div>
                <AccountMenu />
                <div className="max-w-6xl mx-auto p-4 flex flex-col justify-center items-center h-screen">
                    <h2 className="text-3xl text-text_color font-bold mb-4">Subscription Plans</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ml-12">
                        <SubscriptionPlan
                            title="Individual Plan"
                            description="Perfect for individuals."
                            price={9.99}
                            linkTo="/pay"
                            icon="https://gened.as.virginia.edu/sites/gened.as.virginia.edu/files/iStock-1202344480.jpg"
                        />
                        <SubscriptionPlan
                            title="Family Plan"
                            description="Ideal for families."
                            price={19.99}
                            linkTo="/pay"
                            discount={50}
                            icon="https://www.heritagelaw.com/wp-content/uploads/2017/03/shutterstock_1438256546-min-scaled.jpg"
                        />
                        <SubscriptionPlan
                            title="Student Plan"
                            description="Specially for students."
                            price={4.99}
                            linkTo="/pay"
                            icon="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjdPlVp3cZ2_2Tv-16sWB-SqsQbywbbF48xA&s"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Subscription;