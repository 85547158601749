import Header from '../organisms/Header';
import Menu from '../organisms/Menu';
import Lib from "../organisms/LibraryContent";
import AudioPlayer from "../organisms/AudioPlayer";
import {useState} from "react";

interface AccountProps {
    user: any;
    isLoggedIn: boolean;
  }
  
const Library: React.FC<AccountProps> = ({ user, isLoggedIn } : AccountProps) => {
    return (
        <>
        <Header user={user}/>
            <div>
                <Menu/>
                <div className="ml-16 mb-16">
                    <Lib />
                </div>
            </div>
        </>
    );
}

export default Library;