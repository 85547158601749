import React from 'react';
import LibraryElementTitle from "../atoms/LibraryElementTitle";
import type {PlayableSong, Playlist, Song} from "../interfaces"
/*import { doc, getFirestore, setDoc, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
    authDomain: "soundhub-m165.firebaseapp.com",
    projectId: "soundhub-m165",
    storageBucket: "soundhub-m165.appspot.com",
    messagingSenderId: "235438311528",
    appId: "1:235438311528:web:7e01620b75439c2bda93dd",
    measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();*/

function DisplayGrid({ playlists, songs = [] } : { playlists?: Playlist[], songs?: PlayableSong[] }) {
    function onClickPlaylist(index: number) {
        window.location.href = `/playlist?id=${index}`;
    }

    /*     console.log('ran')
  {playlist.map((playlistItem, index) => (
         insertspot = doc(firestore, 'playlists/' + index),
         docData = {
              name: playlistItem.name,
              icon: playlistItem.icon,
              description: playlistItem.description,
              songs: playlistItem.songs,
         },
          setDoc(insertspot, docData)
          //console.log('ran')
  ))}*/


/*    // Hier wird das JSON unserer alten Datenbank eingelesen.
   const jsonSongs = require('../../database/songs.json');
    // Die Struktur des JSONs wird hier definiert.
    jsonSongs.forEach(async (song: { id: number; artist: string; icon: string; title: string; }, index: number) => {
        // Hier Korrigieren wir noch einen Logik fehler in der alten Datenbank (Wir haben bei der Indexierung mit 1 begonnen.
        const newindex = song.id - 1;
        // das Dokument wir mit dem Index als namen in die songs collection eingefügt.
        const insertspot = doc(firestore, 'songs/' + newindex);
        const docData = { // Hier wird das Dokument definiert.
            artist: song.artist,
            title: song.title,
            id: newindex,
        };
        try { // Hier wird das Dokument in die Datenbank eingefügt.
            await setDoc(insertspot, docData); // Correct usage of setDoc with await
            console.log('Document added successfully with id: '  + newindex + ' with artist ' + song.artist + ' and title ' + song.title);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    });*/

    return (
        <div className="pl-8 pt-5 pb-10 flex flex-wrap gap-4 auto-cols-auto">
            {playlists && playlists.map((playlist, index) => (
                <LibraryElementTitle
                    key={index}
                    type="playlist"
                    artist={playlist.description}
                    icon={`https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/Img%2FPlaylist%2F${index}.png?alt=media`}
                    title={playlist.name}
                    onClick={() => {onClickPlaylist(index)}}
                    id={index}
                    songs={playlist.songs}
                />
            ))}
            {songs && songs.map((song, index) => (
                <LibraryElementTitle
                    key={index}
                    type="song"
                    artist={song.artist}
                    icon={`https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/Img%2FSongs%2F${song.id}.png?alt=media`}
                    //link={song.link}
                    title={song.title}
                    onClick={song.onClick} // Pass the index to onClickSong if songs is defined
                    id={index}
                />
            ))}
        </div>
    );
}

export default DisplayGrid;
