import React, { useEffect, useState } from "react";
import DisplayGrid from "../molecules/DisplayGrid";
import type { Playlist } from "../interfaces";
import { doc, getFirestore, setDoc, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
    authDomain: "soundhub-m165.firebaseapp.com",
    projectId: "soundhub-m165",
    storageBucket: "soundhub-m165.appspot.com",
    messagingSenderId: "235438311528",
    appId: "1:235438311528:web:7e01620b75439c2bda93dd",
    measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();

function LibraryContent() {
    const [playlists, setPlaylists] = useState<Playlist[]>([]);

    useEffect(() => {
        async function fetchPlaylists() {
            const playlistsCollection = collection(firestore, "playlists");
            const playlistsSnapshot = await getDocs(playlistsCollection);
            const playlistsData = playlistsSnapshot.docs.map(doc => doc.data() as Playlist);
            setPlaylists(playlistsData);
        }
        
        fetchPlaylists();
    }, []);

    return (
        <div className="ml-10 p-10 pt-100">
            <h1 className="text-2xl text-text_color font-bold">Playlists</h1>
            <DisplayGrid playlists={playlists} />
        </div>
    );
}

export default LibraryContent;
