function SignupTitle() {
  return (
    <>
      <img
        className="mx-auto h-12 w-auto"
        src="/icons/soundhub_logo.png"
        alt="Logo"
      />
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign up for SoundHub
      </h2>
    </>
  );
}

export default SignupTitle;
