import PlaylistSong from "../molecules/PlaylistSong";
import type { PlayableSong, Song } from "../interfaces"

function PlaylistSongs(props: { songs: PlayableSong[] }) {
    let sortsong = props.songs.sort((a, b) => a.id - b.id);
    return (
        <>
            {
                
                sortsong.map((song, index) => {
                    const imgSrc = song.id ? `https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/Img%2FSongs%2F${song.id}.png?alt=media` : ''; 
                    return (
                        <div className="playlistbutton border-gray-400 border my-2" key={index}>
                            <PlaylistSong
                                title={song.title}
                                artist={song.artist}
                                img={imgSrc} // Use the constructed image URL
                                length={'10:00'}
                                onClick={() => song.onClick(song.id)}
                                id={0}
                            />
                        </div>
                    )
                })
            }
        </>
    );
}

export default PlaylistSongs;
