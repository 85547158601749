import { getMouseEventOptions } from "@testing-library/user-event/dist/utils";
import { PlayableSong } from "../interfaces";

/*
interface Song {
    id: number;
    artist: string;
    icon: string;
    title: string;
}

interface PlayableSong extends Song{
    onClick: (index: number) => void;
    progress?: number;
    length?: number;
    playlist?: Playlist;
    songs?: Song[];
}*/

function LibraryElementTitle(props: PlayableSong & { type: string }) {
    if (props.type == "song") {
        return (
            <div className="card w-64 shadow-sm hover:shadow-xl hover:p-1 p-2 image-full bg-opacity-25" onClick={() => props.onClick(props.id)}>
                <figure>< img src={props.icon} alt="Album Cover" /></figure >
                <div className="card-body">
                    <h2 className="card-title text-2xl text-white-100">{props.title}</h2>
                    <p className="text-white-100">{props.artist}</p>
                    <div className="card-actions justify-end">
                        <div className="avatar">
                        </div>
                    </div>
                </div>
            </div >
        )
    } else {
        return (
                <a className="hover:bg-primary delay-50 duration-100 bg-secondary p-2 rounded-lg w-72 group" onClick={() => props.onClick(props.id)} style={{ cursor: "pointer" }}>
                    <img src={props.icon} className="w-full rounded shadow" />
                    <h3 className=" text-black text-2xl font-bold mt-5">{props.title}</h3>
                    <p className="text-black text-lg font-light mt-2 overflow-hidden whitespace-nowrap overflow-ellipsis">{props.artist}</p>
                    {props.songs && (
                    <p className="text-black font-light mt-2 text-sm">
                        {props.songs.length > 1 ? `${props.songs.length} Songs` : props.songs.length === 1 ? '1 Song' : 'No Songs'}
                    </p>
                    )}

                </a>
        )
    }
    {/*<div className="relative w-max">
                            <button onClick={() => props.onClick(props.id)}>
                            <img className="h-60 w-60 rounded-lg hover:scale-105" src={props.icon} alt="" />
                        </button>
                        <div className="w-full mt-2">
                            <h1 className="text-white font-semibold text-3xl">{props.title}</h1>
                            <p className="text-gray-600">{props.artist}</p>
                        </div>
                </div>*/}
}

export default LibraryElementTitle;
