import React, { useEffect, useRef, useState } from "react";
import AudioPlayerRight from "../molecules/AudioPlayerRight";
import AudioPlayerLeft from "../molecules/AudioPlayerLeft";
import AudioPlayerBar from "../molecules/AudioPlayerBar";
import type { Song, AudioPlayerProps, PlayingSong, PlayableSong } from "../interfaces";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
    authDomain: "soundhub-m165.firebaseapp.com",
    projectId: "soundhub-m165",
    storageBucket: "soundhub-m165.appspot.com",
    messagingSenderId: "235438311528",
    appId: "1:235438311528:web:7e01620b75439c2bda93dd",
    measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();

function AudioPlayer({ index, playlist }: AudioPlayerProps) {
    const [playersongs, playersetSongs] = useState<PlayingSong[]>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState("00:00");
    const [duration, setDuration] = useState("00:00");
    const [volume, setVolume] = useState(100);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);
    const [currentSong, setCurrentSong] = useState<PlayingSong>(
        {
            id: 0,
            artist: "Shape of You",
            icon: "https://lh3.googleusercontent.com/TMbA3LImAL96nFkm5cI1x6LiEMvMbRg4IRxEeABd9qpXsfmwKhZHRD1MucnjG5QIsVEp0Vpn7h7M51FH3Q=w544-h544-l90-rj",
            title: "Shape of You",
            mp3: "", onClick(index: number): void {
            }, playlist: undefined, songs: [],
            progress: 0,
            length: 0
        }
    );

    const audioElem = useRef<HTMLAudioElement>(null);

    const skiptoNext = () => {
        const index = playersongs.findIndex((x) => x.title === currentSong.title);
        let nextIndex = index + 1;
    
        if (nextIndex >= playersongs.length) {
            nextIndex = 0; // Loop back to the first song if at the end of the playlist
        }
    
        setCurrentSong(playersongs[nextIndex]);
        setIsPlaying(true); // Start playback of the new song
    
        if (audioElem.current) {
            audioElem.current.currentTime = 0;
            audioElem.current.play(); // Ensure the next song starts playing automatically
        }
    };

    useEffect(() => {
        async function fetchSongs() {
            if (playlist) {
                playersetSongs(playlist as PlayingSong[]);
                console.log(playlist);
            } else {
            const songCollection = collection(firestore, "songs");
            const songsSnapshot = await getDocs(songCollection);
            const songsDataRaw = songsSnapshot.docs.map(doc => doc.data() as PlayingSong);
            let songsData = songsDataRaw.sort((a, b) => a.id - b.id);
            playersetSongs(songsData);
            console.log(songsData);
        }
        }
        fetchSongs();
    }, []);

    useEffect(() => {
        setCurrentSong({ ...playersongs[index] });
        
    }, [index]);

    useEffect(() => {
        const onEnded = () => {
            skiptoNext();
        };
    
        if (audioElem.current) {
            audioElem.current.onended = onEnded;
        }
    
        return () => {
            if (audioElem.current) {
                audioElem.current.onended = null;
            }
        };
    }, [skiptoNext, audioElem]);

    /*const skiptoNext = () => {
        const index = playersongs.findIndex((x) => x.title === currentSong.title);
        const duration = audioElem.current?.duration || 0;
        const currentTime = audioElem.current?.currentTime || 0;

        let nextSong;
        if (index === playersongs.length - 1) {
            nextSong = playersongs[0];
        } else {
            nextSong = playersongs[index + 1];
        }

        setCurrentSong(nextSong);
        setisplaying(true); // Start playback of the new song

        if (audioElem.current) {
            audioElem.current.currentTime = 0;
        }
    };
*/

    useEffect(() => {
        if (isPlaying) {
            audioElem.current?.play();
        } else {
            audioElem.current?.pause();
        }
    }, [isPlaying]);

    const onPlaying = () => {
        const duration = audioElem.current?.duration || 0;
        const currentTime = audioElem.current?.currentTime || 0;

        setCurrentSong(prev => ({
            ...prev,
            progress: (currentTime / duration) * 100,
            length: duration,
        }));
    };

    // Function to format time in minutes:seconds format
const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

    useEffect(() => {
        const updateTime = () => {
            if (audioElem.current) {
                setCurrentTime(formatTime(audioElem.current.currentTime));
                setDuration(formatTime(audioElem.current.duration));
            }
        };
    
        const onLoadedMetadata = () => {
            if (audioElem.current) {
                setDuration(formatTime(audioElem.current.duration));
            }
        };
    
        if (audioElem.current) {
            audioElem.current.addEventListener("timeupdate", updateTime);
            audioElem.current.addEventListener("loadedmetadata", onLoadedMetadata);
        }
    
        return () => {
            if (audioElem.current) {
                audioElem.current.removeEventListener("timeupdate", updateTime);
                audioElem.current.removeEventListener("loadedmetadata", onLoadedMetadata);
            }
        };
    }, [audioElem]);

    const PlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const skipBack = () => {
        const index = playersongs.findIndex((x) => x.title === currentSong.title);
        if (index === 0) {
            setCurrentSong(playersongs[playersongs.length - 1]);
        } else {
            setCurrentSong(playersongs[index - 1]);
        }
        if (audioElem.current) {
            audioElem.current.currentTime = 0;
        }
    };

    const volumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (audioElem.current) {
            const newVolume = Number(e.target.value);
            audioElem.current.volume = newVolume / 100;
            setVolume(newVolume);
        }
    };

    const toggleVolumeSlider = () => {
        setShowVolumeSlider((prev) => !prev);
    };

    if (index === -1) {
        return null;
    } else {
    return (
        <div className="audioplayer bg-white z-40 shadow-xl flex flex-row items-center w-full justify-center fixed bottom-0 h-24">
            <audio ref={audioElem} src={currentSong?.mp3 || "https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/mp3%2F0.mp3?alt=media&token=0956864f-a4e3-4d03-9a39-d90132b062a6"} onTimeUpdate={onPlaying} />
            <AudioPlayerBar
                audioElem={audioElem}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                autoPlayList={playersongs}
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <AudioPlayerLeft
                isPlaying={isPlaying}
                audioElem={audioElem}
                currentSong={currentSong}
                setCurrentSong={setCurrentSong} skipBack={skipBack} PlayPause={PlayPause} skiptoNext={skiptoNext} volumeChange={volumeChange} toggleVolumeSlider={toggleVolumeSlider} volume={volume} currentTime={currentTime} duration={duration} showVolumeSlider={showVolumeSlider} />
            <AudioPlayerRight Title={currentSong.title} Artist={currentSong.artist} Cover={`https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/Img%2FSongs%2F${currentSong.id}.png?alt=media`} />
        </div>
    );
}}

export default AudioPlayer;
