import React from "react";

interface AccountContentProps {
  user: any;
}

const AccountContent: React.FC<AccountContentProps> = ({ user }) => {
  // Format creation date
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    let fdate = date.toLocaleDateString();
    fdate = fdate.replace("/", ".");
    return fdate;
  };

  // Get username from email
  const getUsername = (email: string) => {
    return email.split("@")[0];
  };

  const getUsernameInitals = (email: string) => {
    let expected_name = email.split("@")[0];
    expected_name = expected_name.slice(0, 2);
    return expected_name.toUpperCase();
  };

  const getCreationDate = (timestamp: number) => {
    const test = Math.floor(timestamp / 1000);
    const date = new Date(test * 1000);
    return date.toLocaleDateString();
  };

  return (
    /*<div className="flex flex-col items-center h-screen justify-center">
      <div className="avatar online placeholder m-5">
        <div className="bg-neutral ring ring-primary ring-offset-base-100 ring-offset-2 text-neutral-content rounded-full w-24">
          <span className="text-3xl">{getUsernameInitals(user.auth.currentUser.email)}</span>
        </div>
        </div>
      <div className="text-xl text-text_color font-semibold mb-2">Account-Overview</div>
      <div className="text-text_color">Username: {getUsername(user.auth.currentUser.email)}</div>
      <div className="text-text_color">Creation date: {formatDate(parseInt(user.auth.currentUser.metadata.createdAt))}</div>
      <div className="text-text_color">Language: {"EN"}</div>
      <div className="text-text_color">
        Subscribed since: {"Not subscribed yet"}
      </div>
    </div>*/
    <div className="items-center h-screen w-screen justify-center stats shadow">
      <div className="stat">
        <div className="stat-figure text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-8 h-8 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            ></path>
          </svg>
        </div>
        <div className="stat-title">Total Hours listend</div>
        <div className="stat-value text-secondary">1'254 h</div>
        <div className="stat-desc">Top 10% of all Users</div>
      </div>

      <div className="stat">
        <div className="stat-figure text-secondary">
          <div className="avatar online placeholder m-5">
            <div className="bg-neutral ring ring-primary ring-offset-base-100 ring-offset-2 text-neutral-content rounded-full w-24">
              <span className="text-3xl">
                {getUsernameInitals(user.auth.currentUser.email)}
              </span>
            </div>
          </div>
        </div>
        <div className="stat-value">
          {getUsername(user.auth.currentUser.email.toUpperCase())}
        </div>
        <div className="stat-title">
          Created: {getCreationDate(user.metadata.createdAt)}{" "}
        </div>
        <div className="stat-desc text-secondary">
          Subscribed since: {"Not subscribed yet"}
        </div>
      </div>
    </div>
  );
};

export default AccountContent;
