import Back from "../atoms/back";

function Error404() {
    return (
        <div className="relative h-screen">
            <div className='absolute top-4 left-4' title="Go Back">
                <Back destination="/" path="/icons/back.png"/>
            </div>
            <div className="flex flex-col items-center justify-center h-full">
                <h1 className="text-4xl font-bold text-red-500">404 Not Found</h1>
                <p className="text-lg text-gray-600">Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
}

export default Error404;
