function LoginLink() {
  return (
    <>
      <span>Already have an account?</span>
      <a
        href="/login"
        className="ml-2 font-medium text-indigo-600 hover:text-indigo-500"
      >
        Log in
      </a>
    </>
  );
}

export default LoginLink;
