import React from "react";
import Header from "../organisms/Header";
import AccountMenu from "../organisms/AccountMenu";
import AccountContent from "../organisms/AccountContent";

interface AccountProps {
  user: any;
  isLoggedIn: boolean;
}

const Account: React.FC<AccountProps> = ({ user, isLoggedIn }) => {
  return (
    <>
      <Header user={user} />
      <div>
        <AccountMenu />
      </div>
      {isLoggedIn && <AccountContent user={user} />}
    </>
  );
};

export default Account;