import Back from "../atoms/back";
import SignupTitle from "../organisms/SignupTitle";
import SignupForm from "../organisms/SignupForm";
import LoginLink from "../organisms/LoginLink";
function Signup() {
  return (
    <>
      <div className="absolute top-4 left-4" title="Go Back">
        <Back destination="/" path="/icons/back.png"/>
      </div>
      <div className="darkmode min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 pt-0">
        <div className="max-w-xl w-full space-y-8">
          <SignupTitle />
          <SignupForm />
          <div className="text-center text-sm mt-4">
            <LoginLink />
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
