import HeaderLogo from "../molecules/HeaderLogo";
import HeaderSearchbar from "../molecules/HeaderSearchbar";
import HeaderProfileIcon from "../molecules/HeaderProfileIcon";
import { createTrue } from "typescript";

interface AccountContentProps {
  user?: any;
}

const Header: React.FC<AccountContentProps> = ({ user } : AccountContentProps) => {
//function Header() {
  return (
    <>
      <header className="navigation-header">
        <div className="navigation-logo">
          <HeaderLogo />
        </div>
        <div className="navigation-menu">
          <div className="navigation-searchbar">
            <HeaderSearchbar />
          </div>
          <div className="navigation-profile">
            { user ? <HeaderProfileIcon name={user.email} isLoggedIn={true} /> : <HeaderProfileIcon icon={"./icons/profile_icon.png"} isLoggedIn={false} /> }
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
