import Header from "../organisms/Header";
import Menu from "../organisms/Menu";
import PlaylistIcons from "../molecules/PlaylistIcons";
import PlaylistSongs from "../organisms/PlaylistSongs";
import {type Song, type Playlist, type PlayingSong, type AudioPlayerProps, type PlayableSong} from "../interfaces";
import React, {useEffect, useState} from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import AudioPlayer from "../organisms/AudioPlayer";

const firebaseConfig = {
    apiKey: "AIzaSyBbD6sPaOZgY1C0mwWZQ-Kp18wDSgN6mo0",
    authDomain: "soundhub-m165.firebaseapp.com",
    projectId: "soundhub-m165",
    storageBucket: "soundhub-m165.appspot.com",
    messagingSenderId: "235438311528",
    appId: "1:235438311528:web:7e01620b75439c2bda93dd",
    measurementId: "G-HBPMS8D4XE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();

interface AccountProps {
    user: any;
    isLoggedIn: boolean;
}

const Playlistpage: React.FC<AccountProps> = ({ user, isLoggedIn }: AccountProps) => {
    const [songs, setSongs] = useState<PlayableSong[]>([]);
    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedSong, setSelectedSong] = useState<AudioPlayerProps>({ index: -1 });

    useEffect(() => {
        async function fetchSongs() {
            const songCollection = collection(firestore, "songs");
            const songsSnapshot = await getDocs(songCollection);
            const songsDataRaw = songsSnapshot.docs.map(doc => doc.data() as PlayableSong);
            let songsData = songsDataRaw.sort((a, b) => a.id - b.id);
            setSongs(songsData);
        }

        async function fetchPlaylists() {
            const playlistsCollection = collection(firestore, "playlists");
            const playlistsSnapshot = await getDocs(playlistsCollection);
            const playlistsData = playlistsSnapshot.docs.map(doc => doc.data() as Playlist);
            setPlaylists(playlistsData);
        }

        async function fetchData() {
            await Promise.all([fetchSongs(), fetchPlaylists()]);
            setLoading(false);
        }

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!playlists || playlists.length === 0) {
        return <div>No playlists found</div>;
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const id = parseInt(queryParameters.get("id") as string);

    if (id >= playlists.length) {
        return <div>Invalid playlist id</div>;
    }

    const selectedSongs: PlayableSong[] = (songs as PlayableSong[]) || [];
    selectedSongs.forEach((song, index) => {
        song.onClick = () => onClick(index -1);
    });
    let playlistSongSelection: PlayableSong[] = [];

    playlists[id].songs.map((song: number) => {
        return playlistSongSelection.push(selectedSongs[song]);
    });

    let playlistSongSelectionSort = playlistSongSelection.sort((a, b) => a.id - b.id); // Sort the playlist songs by id

    const onClick = (index: number): void => {
        setSelectedSong({ index: index, playlist: playlistSongSelectionSort }); // Add the 'playlist' property 
    };

    return (
        <>
            <Header user={user} />
            <div>
                <Menu />
                <div className="ml-16 text-center p-20">
                    <img alt="PlaylistIcon" className="rounded-lg mx-auto w-96 h-96 mb-4" src={'https://firebasestorage.googleapis.com/v0/b/soundhub-m165.appspot.com/o/Img%2FPlaylist%2F' + id + '.png?alt=media'}></img>
                    <h1 className="mb-4 text-4xl">{playlists[id].name}</h1>
                    <p className="mb-4">{playlists[id].description}</p>
                    <PlaylistIcons className="flex justify-center"/>
                    <hr/>
                    <PlaylistSongs songs={playlistSongSelection}/>
                </div>
                <AudioPlayer index={selectedSong.index} playlist={playlistSongSelectionSort as PlayingSong[]}/>
            </div>
        </>
    );
}

export default Playlistpage;