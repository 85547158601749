function SignupLink() {
  return (
    <>
      <span>Don't have an account?</span>
      <a
        href="/signup"
        className="ml-2 font-medium text-indigo-600 hover:text-indigo-500"
      >
        Sign up
      </a>
    </>
  );
}

export default SignupLink;
